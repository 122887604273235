import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { useRef, useState } from "react";
import ListItem from "../listItem/ListItem";
import "./list.scss";

export default function List({ listTitle, listData }) {
  const [isMoved, setIsMoved] = useState(false);
  const [slideNumber, setSlideNumber] = useState(0);
  const [rightArrow, setRightArrow] = useState(false);
  const [scrollX, setScrollX] = useState(0);

  const listRef = useRef();
  const handleLeftArrow = () => {
    let x = scrollX + Math.round(window.innerWidth / 3);
    if (slideNumber > 0) {
      setSlideNumber(slideNumber - 1);
    }
    setRightArrow(false);

    if (x > 0) {
      setIsMoved(false);
      x = 0;
    }
    setScrollX(x);
  };

  const handleRightArrow = () => {
    setIsMoved(true);
    let x = scrollX - Math.round(230 * 3);
    if (slideNumber < listData.length + 5) {
      setSlideNumber(slideNumber + 1);
    }
    let listW = listData.length;
    let limit = 0 - listW * 240 + window.innerWidth;
    if (slideNumber < 2) {
      if (window.innerWidth - listW < x) {
        x = window.innerWidth - listW - 50;
      }
    }
    if (limit > x) {
      setRightArrow(true);
    }
    if (x > limit) {
      setRightArrow(false);
    }
    // console.log(limit, x);
    setScrollX(x);
  };

  const handleClick = (direction) => {
    setIsMoved(true);
    let distance = listRef.current.getBoundingClientRect().x - 50;

    if (direction === "left" && slideNumber > 0) {
      setSlideNumber(slideNumber - 1);
      listRef.current.style.transform = `translateX(${230 + distance}px)`;
    }
    if (direction === "right" && slideNumber < listData.length + 5) {
      setSlideNumber(slideNumber + 1);
      listRef.current.style.transform = `translateX(${-230 + distance}px)`;
    }
  };
  let listRow = [];

  if (listData && Array.isArray(listData)) {
    // console.log('Handling listData as an ARRAY for title:', listTitle);
    listRow = listData.map((item, index) => (
      <ListItem key={index} index={index} data={item} />
    ));
  } else if (listData && typeof listData === "object") {
    // This ELSE is no longer needed
    // console.log("Handling listData as an OBJECT for title:", listTitle);
    listRow = Object.keys(listData).map((key, index) => {
      // let seriesName = listData[key].seriesName;
      let seasonObj = listData[key].seasons;
      let firstSeasonKey = Object.keys(seasonObj)[0];
      let firstVideo = seasonObj[firstSeasonKey].videos[0];
      // let fullSeriesData = listData[key];
      // firstVideo.fullSeriesData = fullSeriesData;
      return <ListItem key={index} index={index} data={firstVideo} />;
    });
  }

  return (
    <div className="list">
      <span className="listTitle">{listTitle || "Unassorted Category"}</span>
      <div className="wrapper">
        {listData?.length > 6 && (
          <ArrowBackIosOutlinedIcon
            className="sliderArrow left"
            // onClick={() => handleClick("left")}
            onClick={handleLeftArrow}
            style={{ display: !isMoved && "none", zIndex: 1000 }}
          />
        )}
        <div
          className="container"
          ref={listRef}
          style={{
            marginLeft: scrollX,
          }}
        >
          {listRow}
        </div>
        {listData?.length > 6 && !rightArrow && (
          <ArrowForwardIosOutlinedIcon
            className="sliderArrow right"
            // onClick={() => handleClick("right")}
            onClick={handleRightArrow}
            sx={{ zIndex: 1000 }}
          />
        )}
      </div>
    </div>
  );
}
