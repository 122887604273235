import React from "react";
import { Grid, Typography } from "@mui/material";
import ListItem from "../listItem/ListItem";
import Navbar from "../navbar/Navbar";
import { useSelector } from "react-redux";
import { getSearchResults } from "../../pages/search/searchSlice";
import { getChannelDataHome } from "../../pages/home/homeSlice";
import { getSearchedItem } from "../../pages/search/searchedItemSlice";
import { getUpdatedRoutes } from "../../App";

const SearchResults = () => {
  const searchDatasobj = useSelector(getSearchResults);
  const totalResults = searchDatasobj.length;
  const channelDataHome = useSelector(getChannelDataHome);
  const searchedItemRedux = useSelector(getSearchedItem);
  const newArrayofobj = { ...channelDataHome, search: searchDatasobj };
  const filteredRoutes = getUpdatedRoutes(channelDataHome);
  // console.log(newArrayofobj);
  return (
    <>
      <Navbar navData={filteredRoutes} />
      <Grid
        sx={{
          p: 15,
          color: "var(--FONT_COLOR)",
          minHeight: "100vh",
          background: "var(--THEME_COLOR)",
        }}
        gap={4}
      >
        {totalResults === 0 && (
          <Typography variant="h4" sx={{ textAlign: "center" }}>
            No Results Found...
          </Typography>
        )}
        {totalResults > 0 && (
          <Typography variant="h4" mb={3}>
            Search Results of {searchedItemRedux}
          </Typography>
        )}
        {totalResults > 0 && (
          <Grid container gap={3}>
            {newArrayofobj["search"].map((item, index) => (
              <Grid
                item
                container
                key={index}
                xs={2}
                gap={0.5}
                direction="column"
              >
                <ListItem data={item} index={index} search={true} />
                <Typography>Title: {item.videoDesc} </Typography>
                <Typography>Category: {item.videoCategory}</Typography>
                <Typography>
                  Genre: {item.videoGenres.map((g) => `${g},`)}{" "}
                </Typography>
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default SearchResults;
