import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  channelDataHome: {},
};

const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    setChannelDataHome: (state, action) => {
      state.channelDataHome = action.payload;
    }
  }
});

export const { setChannelDataHome } = homeSlice.actions;
export const getChannelDataHome = (state) => state.homeSlice.channelDataHome;
export default homeSlice.reducer;