import { Link } from "react-router-dom";
import "./404.scss";
export default function NotFound() {
  return (
    <div className="page-wrapper">
      <h1>404 </h1>
      <h2>Oops! You seem to be lost.</h2>
      <Link to="/" className="back-home">
        Back to Home..
      </Link>
    </div>
  );
}
