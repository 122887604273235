import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedVideoData: {},
};

const listItemSlice = createSlice({
  name: "listItem",
  initialState,
  reducers: {
    setSelectedVideoData: (state, action) => {
      state.selectedVideoData = action.payload;
    }
  }
});

export const { setSelectedVideoData } = listItemSlice.actions;
export const getSelectedVideoData = (state) => state.listItemSlice.selectedVideoData;
export default listItemSlice.reducer;