// import React, { useState, useEffect } from 'react'
// import axios from 'axios';
// import axiosapi from '../../common/axiosapi';
// import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getSelectedVideoData } from '../../components/listItem/listItemSlice';
import { getChannelDataHome } from '../home/homeSlice';
import FeaturedSection from '../../components/featuredSection/FeaturedSection';
import SeriesSection from '../../components/series/SeriesSection';
import Navbar from '../../components/navbar/Navbar';
import List from '../../components/list/List';
import './single.scss';

function shuffleArray(inputArray) {
  const outArray = [...inputArray];
  for (let i = outArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = outArray[i];
      outArray[i] = outArray[j];
      outArray[j] = temp;
  }
  return outArray;
}

function getSimilarVideos(selectedVideo, fullData) {
  const similarVideosList = [];
  const selectedGenres = selectedVideo.videoGenres || [];
  const selectedTags = selectedVideo.videoTags || [];
  const selectedCategory = selectedVideo.videoCategory || undefined;
  const selectedVideoId = selectedVideo.id || 0;
  const keysToSkip = ["SeriesList", "slider", "title"];

  const fullDataKeys = Object.keys(fullData);
  fullDataKeys.forEach(key => {
    if(keysToSkip.includes(key)) return;
    if(key === selectedCategory || selectedGenres.includes(key)) { // get all videos from same category/genres of selectedVideo
      similarVideosList.push(...fullData[key]);
    } else { // for non-matching, check for matching Tags
      const currentList = fullData[key];
      currentList.forEach(vid => {
        if(vid.videoTags && vid.videoTags.length && selectedTags.length){
          if(vid.videoTags.some(elem => selectedTags.includes(elem))) {
            similarVideosList.push(vid);
          }
        }
      });
    }
  });
  const keyId = "id";
  const uniqueVideos = [...new Map(similarVideosList.map(item =>
    [item[keyId], item])).values()];
  const excludeSelfList = uniqueVideos.filter((item) => item.id !== selectedVideoId);
  const shuffleList = shuffleArray(excludeSelfList);
  let listLimit = (shuffleList.length > 2) ? 2 : shuffleList.length;
  const outDataList = shuffleList.slice(0,listLimit);    // 3 featured videos jumbo
  return outDataList;
}

const Single = () => {
  // const [data, setData] = useState({});
  // let {id} = useParams();
  // console.log('id from params', id);
  const selectedVideoData = useSelector(getSelectedVideoData);
  const channelDataHome = useSelector(getChannelDataHome);
  const selectedVideoSeriesId = selectedVideoData.videoSeriesId;
  // console.log('selectedVideo videoId in Single: ', selectedVideoData.id);
  // console.log('selectedVideoSeriesId in Single: ', selectedVideoSeriesId);

  let selectedSeriesData;
  if (selectedVideoSeriesId) { // then we are processing a series
    // console.log('dealing with series');
    const seriesList = channelDataHome.SeriesList;
    // console.log('seriesList', seriesList);
    for (const key of Object.keys(seriesList)) {
      // console.log('key', key);
      let series = seriesList[key];
      // console.log('current series', series);
      let seasonObj = series.seasons;
      // console.log('seasonObj', seasonObj);
      let firstSeasonKey = Object.keys(seasonObj)[0];
      // console.log('firstSeasonKey', firstSeasonKey);
      let firstVideo = seasonObj[firstSeasonKey].videos[0];
      // console.log('firstVideo', firstVideo);
      if (firstVideo.videoSeriesId === selectedVideoSeriesId) {
        // console.log('found seriesId match', firstVideo.videoSeriesId);
        selectedSeriesData = series;
        break;
      }
    }
  }
  
  // Conditionally render the component based on the selectedVideoSeriesId
  // if selectedVideoSeriesId is defined, render SeriesSection
  // else render FeaturedSection
  
  return ( <div className='single'>
      <Navbar />
      {(selectedSeriesData || selectedVideoData) ? (selectedVideoSeriesId ? (
          <SeriesSection type={'Series'} data={selectedSeriesData} />
      ) : (
        <>
          <FeaturedSection type={selectedVideoData.title} data={[selectedVideoData]} />
          <List listTitle="Similar Videos" listData={getSimilarVideos(selectedVideoData, channelDataHome)} />
        </>
      )) : (
        <div>
          <h1>No data found</h1>
        </div>
      )}
    </div>
  );

};

export default Single
