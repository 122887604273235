import React, { useEffect, useState } from "react";
import axiosapi from "../../common/axiosapi";
import Navbar from "../../components/navbar/Navbar";
import FeaturedSection from "../../components/featuredSection/FeaturedSection";
import List from "../../components/list/List";
import { useDispatch, useSelector } from "react-redux";
import { setChannelDataHome, getChannelDataHome } from "./homeSlice";
import "./home.scss";
import HomeSkeleton from "../../components/homeSkeleton/homeSkeleton";
import { getUpdatedRoutes } from "../../App";
import { getSearchResults } from "../search/searchSlice";

function getFirstEpisodes(listData) {
  const listRow = Object.keys(listData).map((key) => {
    let seasonObj = listData[key].seasons;
    let seasonObjKeys = Object.keys(seasonObj);
    let firstSeasonKey = seasonObjKeys[0];
    let firstVideo = seasonObj[firstSeasonKey].videos[0];
    return firstVideo;
  });
  return listRow;
}

function getSeriesSeasonList(seriesData) {
  const seriesSeasonData = {};
  Object.keys(seriesData).forEach((eachSeries) => {
    const seasonObject = seriesData[eachSeries].seasons;
    Object.keys(seasonObject).forEach((eachSeason) => {
      const rowName = `${eachSeries} - ${eachSeason}`;
      seriesSeasonData[rowName] = seasonObject[eachSeason].videos;
    });
  });
  return seriesSeasonData;
}
const Home = ({ type }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const dispatch = useDispatch();
  const channelDataHome = useSelector(getChannelDataHome);
  const filteredRoutes = getUpdatedRoutes(channelDataHome);

  const pageType = type || "Home";

  useEffect(() => {
    setLoading(true);
    setSearchResults([]);
    const getDBData = async () => {
      try {
        let dbResp = await axiosapi.get(`/channelui/mainpage/Home`); // hardcoded Home, get full data initially
        // let dbResp = await axiosapi.get(`/channelui/mainpage/${pageType}`);
        const datas = dbResp.data;
        const updatedData = dispatch(setChannelDataHome(dbResp.data));
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
        setError(true);
      }
    };
    getDBData();
  }, [dispatch]);

  let channelRows = [];
  Object.keys(channelDataHome).forEach((key) => {
    let keyUpper = key.toUpperCase();
    // console.log('key', key);
    // console.log('channelDataHome[key]', channelDataHome[key]);
    // if (key === 'title' || key === 'slider' || key === 'SeriesList') {
    //   // Skip now, added as Featured & SeriesHome sections after the loop
    // }
    if (key === "featured") {
      if (pageType === "Home") {
        // if Home, show all pageTypes
        channelRows.splice(
          0,
          0,
          <List
            key={key}
            listTitle={keyUpper}
            listData={channelDataHome[key]}
          />
        );
      } else {
        // If pageType is specified, filter for the same
        let typeFiltered = channelDataHome[key].filter(
          (item) => item.videoCategory === pageType
        );
        if (typeFiltered && typeFiltered.length > 0) {
          channelRows.splice(
            0,
            0,
            <List key={key} listTitle={keyUpper} listData={typeFiltered} />
          );
        }
      }
    }
    if (pageType === "Home" && key === "Movie") {
      channelRows.splice(
        1,
        0,
        <List key={key} listTitle={keyUpper} listData={channelDataHome[key]} />
      );
    }
    if (pageType === "Home" && key === "Series") {
      channelRows.splice(
        1,
        0,
        <List key={key} listTitle={keyUpper} listData={channelDataHome[key]} />
      );
    }
    if (pageType === "Home" && key === "ShortFilm") {
      channelRows.splice(
        1,
        0,
        <List key={key} listTitle={keyUpper} listData={channelDataHome[key]} />
      );
    }
    if (
      ![
        "Movie",
        "Series",
        "ShortFilm",
        "title",
        "slider",
        "featured",
        "SeriesList",
      ].includes(key)
    ) {
      // We are dealing with Genres list
      if (pageType === "Home") {
        // if Home, show all pageTypes
        channelRows.push(
          <List
            key={key}
            listTitle={keyUpper}
            listData={channelDataHome[key]}
          />
        );
      } else {
        // If pageType is specified, filter for the same
        let typeFiltered = channelDataHome[key].filter(
          (item) => item.videoCategory === pageType
        );
        if (typeFiltered && typeFiltered.length > 0) {
          channelRows.push(
            <List key={key} listTitle={keyUpper} listData={typeFiltered} />
          );
        }
      }
    }
  });

  if (pageType === "Series" && channelDataHome.SeriesList) {
    const firstEpisodesList = getFirstEpisodes(channelDataHome.SeriesList);
    channelRows.splice(
      1,
      0,
      <List
        key={"SeriesList"}
        listTitle={"FIRST EPISODES"}
        listData={firstEpisodesList}
      />
    );
    // generate one row for each series/season
    const seriesSeasonList = getSeriesSeasonList(channelDataHome.SeriesList);
    Object.keys(seriesSeasonList).forEach((eachSeries, index) => {
      channelRows.splice(
        2,
        0,
        <List
          key={`${eachSeries}-${index}`}
          listTitle={eachSeries}
          listData={seriesSeasonList[eachSeries]}
        />
      );
    });
  }
  if (pageType === "Home") {
    // if Home, show all pageTypes
    channelRows.splice(
      0,
      0,
      <FeaturedSection key={0} type={pageType} data={channelDataHome.slider} />
    );
  } else {
    // If pageType is specified, filter for the same
    let typeFiltered = channelDataHome.slider.filter(
      (item) => item.videoCategory === pageType
    );
    if (typeFiltered && typeFiltered.length > 0) {
      channelRows.splice(
        0,
        0,
        <FeaturedSection key={0} type={pageType} data={typeFiltered} />
      );
    } else {
      channelRows.splice(
        0,
        0,
        <FeaturedSection
          key={0}
          type={pageType}
          data={channelDataHome[pageType]}
        />
      );
    }
  }

  return (
    <div className="home">
      <Navbar navData={filteredRoutes} />
      {loading ? (
        <HomeSkeleton />
      ) : (
        <div className="genres">{channelRows.map((row) => row)}</div>
      )}
    </div>
  );
};

export default Home;
