import React, { useState } from "react";
import { InputAdornment, TextField } from "@mui/material";
import styled from "@emotion/styled";
import { grey } from "@mui/material/colors";
import SearchIcon from "@mui/icons-material/Search";

const SearchField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    height: 50,
    p: 1,
    color: "white",
    borderRadius: "10px",
    "& fieldset": {
      borderColor: grey[200],
    },
    "&:hover fieldset": {
      borderColor: grey[300],
    },
    "&.Mui-focused fieldset": {
      borderColor: grey[300],
    },
  },
});

const SearchInput = ({
  width = 300,
  placeHolder = "Search by Title ",
  handleSearch,
}) => {
  const [searchItem, setSearchItem] = useState("");
  return (
    <SearchField
      sx={{ width: width, p: "2px", m: 1 }}
      onChange={(e) => setSearchItem(e.target.value)}
      variant="outlined"
      InputProps={{
        placeholder: placeHolder,
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon
              sx={{ color: "#fff", cursor: "pointer" }}
              onClick={() => handleSearch(searchItem)}
            />
          </InputAdornment>
        ),
      }}
      fullWidth
    />
  );
};

export default SearchInput;
