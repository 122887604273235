import React, { useState } from "react";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import AddIcon from "@mui/icons-material/Add";
import InfoIcon from "@mui/icons-material/Info";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import ThumbDownOutlinedIcon from "@mui/icons-material/ThumbDownOutlined";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSelectedVideoData } from "./listItemSlice";
import "./listItem.scss";
import ReactPlayer from "react-player";
import { getFormattedDate } from "../../utils/getFormattedDate";
import { getFormattedTime } from "../../utils/getFormattedTime";

export default function ListItem({ index, data, search }) {
  const [isHovered, setIsHovered] = useState(false);

  const dispatch = useDispatch();

  const setVideoData = () => {
    dispatch(setSelectedVideoData(data));
  };


  // const trailerURI =
  //   data?.videoSource?.hd ||
  //   data?.videoSource?.hsd ||
  //   data?.videoSource?.sd ||
  //   data?.videoSource?.ld ||
  //   "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4";
  // const trailer = trailerURI ? `https://${trailerURI}` : "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4";

  const trailerURI = data?.videoWebp;
  const trailer = trailerURI ? `https://${trailerURI}` : (data?.videoImage || "https://picsum.photos/536/354");
  // const encodedUrl = encodeURIComponent(trailer);

  const imgURI = data?.videoThumbNail || "https://picsum.photos/536/354";
  const playerRef = React.useRef();
  const onReady = React.useCallback(() => {
    const timeToStart = 7 * 60 + 12.6;
    playerRef.current.seekTo(timeToStart, "seconds");
  }, []);

  if (search) {
    return (
      <div className="searchItem">
        <Link to={"/watch/" + data.id}>
          <img
            // src="https://picsum.photos/536/354"
            src={imgURI}
            alt=""
          />
        </Link>
      </div>
    );
  }
  return (
    <>
      {" "}
      {data && (
        <div
          className="listItem"
          style={{ left: isHovered && index * 225 - 50 + index * 2.5 }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <img
            // src="https://picsum.photos/536/354"
            src={imgURI}
            alt=""
          />
          {isHovered && (
            <div className="video-container">
              {/* <div>
                <ReactPlayer
                  ref={playerRef}
                  playing
                  url={trailer}
                  width="100%"
                  height="50%"
                  className="video-player"
                  loop
                  muted
                  controls
                  onProgress={(e) => {
                    const timeplayed = Math.round(e.playedSeconds);
                    if (timeplayed === 6) {
                      playerRef.current.seekTo(0, "seconds");
                    }
                  }}
                />
              </div> */}
              <div className="image-player">
                <img
                    src={trailer}
                    alt=""
                />
              </div>

              <div className="itemInfo">
                <div className="icons">
                  {/* <Link to={"/watch/" + encodedUrl}> */}
                  <Link to={"/watch/" + data.id}>
                    <PlayArrowIcon className="icon" />
                  </Link>
                  <Link to={"/single/" + data.id}>
                    {/* <Link to={{pathname:`/single/${data.id}`, query:{'obj': JSON.stringify(data)}}}> */}
                    {/* <Link to={{pathname:`/single/${data.id}`, state:{ fromDashboard: '25', toDash: 35 }}}> */}
                    {/* <Link to={{pathname:`/single/${data.id}`,query:JSON.stringify(data)}}> */}
                    <InfoIcon className="icon" onClick={setVideoData} />
                  </Link>
                  <AddIcon className="icon" />
                  <ThumbUpAltOutlinedIcon className="icon" />
                  <ThumbDownOutlinedIcon className="icon" />
                </div>
                <div className="itemInfoTop">
                  <span>{getFormattedTime(data.videoDuration)}</span>
                  <span className="limit">+16</span>
                  <span>{getFormattedDate(data.videoPublish)}</span>
                </div>
                <div className="desc">
                  {data.videoDesc || "No description available for this video"}
                </div>
                <div className="genre">{[data.videoGenres].join(" ")}</div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}
