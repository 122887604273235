import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  searchResults: [{}],
};

export const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setSearchResultsArray: (state, action) => {
      state.searchResults = action.payload;
    },
    cleanupSearchResults: (state) => {
      state.searchResults = [];
    },
  },
});

export const { setSearchResultsArray, cleanupSearchResults } =
  searchSlice.actions;
export const getSearchResults = (state) => state.searchSlice.searchResults;

export default searchSlice.reducer;
