import React, { Component } from "react";

import createEventHandlers from "./create-event-handlers/create-event-handlers";
import injectPlayerScript from "./helpers/inject-player-script";
import removeBridPlayerInstance from "./helpers/remove-brid-player-instance";

import config from "../../config";

// const displayName = "ReactBridPlayer";
const displayName = config.client.CHANNEL_CLIENT_NAME ?  config.client.CHANNEL_CLIENT_NAME+"Player" : "VideoPlayer";
class ReactBridPlayer extends Component {
  constructor(props) {
    super(props);
    this.scriptSrc = "//services.brid.tv/player/build/brid.min.js";
    this.uniqueScriptId = "brid-player-script";
    this.playerConfig = {
      id: this.props.id,
      width: this.props.width,
      height: this.props.height,
    };
    (this.props.video) ? this.playerConfig.video = this.props.video : this.playerConfig.playlist = this.props.playlist;
    this.state = { ready: "false", div: {} };

    // this.onAdImpression = this.props.onAdImpression;
    // this.onAdImpression = this.onAdImpression.bind(this);
  }

  // onAdImpression(event) {
  //   console.log("onAdImpression-RBP: ", event);
  // }

  componentDidMount() {
    injectPlayerScript({
      context: document,
      onLoadCallback: this.initialize,
      scriptSrc: this.scriptSrc,
      uniqueScriptId: this.uniqueScriptId
    });
  }

  componentWillUnmount() {
    removeBridPlayerInstance(this.props.divId, window);
  }

  initialize = () => {
    const player = window.$bp(this.props.divId, this.playerConfig, this.props.playerReady);
    this.setState({ ready: true, div: player });
    window.$bp(this.props.divId, this.playerConfig);
    this.eventHandlers = createEventHandlers(this, window.$bp);
  }

  render() {
    return (
      <div
        id={this.props.divId}
        className="brid"
        style={{ width: this.props.width, height: this.props.height }}
      />
    );
  }
}

ReactBridPlayer.displayName = displayName;
export default ReactBridPlayer;
