import { configureStore } from "@reduxjs/toolkit";

// import {rootReducer} from './rootReducer';
import homeSliceReducer from "./pages/home/homeSlice";
import listItemSliceReducer from "./components/listItem/listItemSlice";
import searchSliceReducer from "./pages/search/searchSlice";
import searchedItemSliceReducer from "./pages/search/searchedItemSlice";

export const store = configureStore({
  reducer: {
    // homeSlice: homeSlice.reducer,
    homeSlice: homeSliceReducer,
    listItemSlice: listItemSliceReducer,
    searchSlice: searchSliceReducer,
    searchedItemSlice: searchedItemSliceReducer,
  },
});
