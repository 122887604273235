import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";

const HomeSkeleton = () => {
  return (
    <Stack spacing={3}>
      <Skeleton
        animation="wave"
        variant="rectangular"
        height={550}
        sx={{ backgroundColor: "grey.800" }}
      />
      <Stack>
        {[1, 2, 3, 4, 5, 6].map((i) => (
          <Grid key={i} container wrap="nowrap">
            <Grid container direction="column" sx={{ mx: 5 }}>
              <Skeleton
                variant="text"
                width={120}
                height={50}
                sx={{ backgroundColor: "grey.800" }}
              />
              <Stack direction="row" spacing={1}>
                {[1, 2, 3, 4, 5, 6].map((i) => (
                  <Skeleton
                    key={i}
                    variant="rounded"
                    width={230}
                    height={120}
                    sx={{ backgroundColor: "grey.800" }}
                  />
                ))}
              </Stack>
            </Grid>
          </Grid>
        ))}
      </Stack>
    </Stack>
  );
};

export default HomeSkeleton;
