import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import "./App.scss";
import Home from "./pages/home/Home";
import Watch from "./pages/watch/Watch";
import Login from "./pages/login/Login";
import Register from "./pages/register/Register";
import Single from "./pages/single/Single";
import NotFound from "./pages/404/404";
import { useSelector } from "react-redux";
import { getChannelDataHome } from "./pages/home/homeSlice";
import SearchResults from "./components/search-results/searchResults";

export function getUpdatedRoutes(channelData) {
  const mainRoutes = ["Movie", "Series", "ShortFilm"];
  let userRoutes = Object.keys(channelData).map((eachKey) => {
    if (mainRoutes.includes(eachKey)) {
      if (channelData[eachKey].length) return eachKey;
    } else {
      return eachKey;
    }
  });
  userRoutes = userRoutes.filter((n) => n !== undefined);
  return userRoutes;
}

function App() {
  const user = true; // to be implemented with proper auth
  const channelDataHome = useSelector(getChannelDataHome);
  const filteredRoutes = getUpdatedRoutes(channelDataHome);

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={user ? <Home /> : <Navigate to="/login" replace />}
        />
        <Route
          path="/login"
          element={!user ? <Login /> : <Navigate to="/" replace />}
        />
        <Route path="*" element={<NotFound />} />
        <Route
          path="/register"
          element={!user ? <Register /> : <Navigate to="/" replace />}
        />
        {user && (
          <>
            {filteredRoutes.includes("Movie") && (
              <Route path="/movies" element={<Home type={"Movie"} />} />
            )}
            {filteredRoutes.includes("Series") && (
              <Route path="/series" element={<Home type={"Series"} />} />
            )}
            {filteredRoutes.includes("ShortFilm") && (
              <Route path="/shortfilms" element={<Home type={"ShortFilm"} />} />
            )}
            <Route path="/single/:id" element={<Single />} />
            <Route path="/watch/:vidUrl" element={<Watch />} />
            {/* <Route path="/mylist" element={ <Home />} /> */}
            <Route path="/search" element={<SearchResults />} />
          </>
        )}
      </Routes>
    </Router>
  );
}

export default App;
