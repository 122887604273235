import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  searchedItem: "",
};

export const searchedItemSlice = createSlice({
  name: "searchedItem",
  initialState,
  reducers: {
    setSearchedItem: (state, action) => {
      state.searchedItem = action.payload;
    },
    cleanupSearchedItem: (state) => {
      state.searchedItem = [];
    },
  },
});

export const { setSearchedItem, cleanupSearchedItem } =
  searchedItemSlice.actions;
export const getSearchedItem = (state) => state.searchedItemSlice.searchedItem;

export default searchedItemSlice.reducer;
