import React from "react";
import { Link } from "react-router-dom";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import "./featuredSection.scss";
import { getFormattedDate } from "../../utils/getFormattedDate";
import { getFormattedTime } from "../../utils/getFormattedTime";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  borderRadius: 5,
  p: 4,
};

export default function FeaturedSection({ type, data }) {
  // console.log("data received in featuredSection:", data);

  const [openBool, setOpenBool] = React.useState(false);
  const handleInfoOpen = () => setOpenBool(true);
  const handleInfoClose = () => setOpenBool(false);

  let selectedType = "";
  switch (type) {
    case "Featured":
      selectedType = "Featured";
      break;
    case "Movie":
      selectedType = "Movies";
      break;
    case "Series":
      selectedType = "Series";
      break;
    case "ShortFilm":
      selectedType = "Short Films";
      break;
    // case "Mylist":
    //   selectedType = 'My List';
    //   break;
    default:
      break;
  }

  const dataObj =  data && data.length > 0 ? data[0] : {};
  let imageUrl = dataObj.videoImage || "https://picsum.photos/1920/1080";


  return (
    <div className="featuredSection">
      {type && (
        <div className="category">
          <span>{selectedType}</span>
        </div>
      )}
      {data && (
        <>
          <img src={imageUrl} alt="Slider Video" />
          <div className="info">
            {/* <img
              src="https://picsum.photos/536/354"
              alt=""
            /> */}
            <div className="title">
              <h2>{dataObj.videoTitle || "No Title"}</h2>
            </div>
            <span className="desc">
              {dataObj.videoDesc || "No additional info available"}
            </span>
            <div className="buttons">
              {/* <Link to={`/watch/${encodedUrl}`}> */}
              <Link to={dataObj.id ? `/watch/${dataObj.id}` : "#"}>
                <button className="play">
                  <PlayArrowIcon />
                  <span>Play</span>
                </button>
              </Link>
              <button className="more">
                <InfoOutlinedIcon />
                <span onClick={handleInfoOpen}>Info</span>
                <Modal
                  open={openBool}
                  onClose={handleInfoClose}
                  aria-labelledby={dataObj.videoTitle || "Unknow Title"}
                  aria-describedby={dataObj.videoDesc || "No additional info available"}>
                  <Box sx={style}>
                    {dataObj.videoDesc || "No additional info available"} <br/>
                    <br/>Genres: {[dataObj.videoGenres].length ? [dataObj.videoGenres].join(",") : "No genres info available"}
                    <br/>Duration: {getFormattedTime(dataObj.videoDuration) || "No duration info available"}
                    <br/>Published: {getFormattedDate(dataObj.videoPublish) || "No published info available"}
                    <br/>Tagged with: {[dataObj.videoTags].length ? [dataObj.videoTags].join(",") : "No Tags info available"}
                    <br/>Category: {dataObj.videoCategory || "Unassorted video"}
                  </Box>
                </Modal>
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
