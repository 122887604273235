import React, { useState } from "react";
import { Link } from "react-router-dom";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import List from "../list/List";
import { getFormattedDate } from "../../utils/getFormattedDate";
import { getFormattedTime } from "../../utils/getFormattedTime";
import "./series.scss";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function SeriesSection({type, data }) {
  // console.log("data received in Series-Section:", data);
  let selectedType = "Series";
  let seasonsObj = data.seasons;
  let seasonList = Object.keys(seasonsObj);
  const [selectedSeason, setSelectedSeason] = useState(seasonList[0]);
  const [openBool, setOpenBool] = React.useState(false);
  const handleInfoOpen = () => setOpenBool(true);
  const handleInfoClose = () => setOpenBool(false);


  let seasonVideosList = seasonsObj[selectedSeason].videos;
  let imageUrl =
    (seasonVideosList &&
      seasonVideosList.length > 0 &&
      seasonVideosList[0].videoImage) ||
    "https://images.pexels.com/photos/6899260/pexels-photo-6899260.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500";
  let videoURL =
    (seasonVideosList &&
      seasonVideosList.length > 0 &&
      (seasonVideosList[0].videoSource?.hd ||
        seasonVideosList[0].videoSource?.hsd ||
        seasonVideosList[0].videoSource?.sd ||
        seasonVideosList[0].videoSource?.ld)) ||
    "player.vimeo.com/external/371433846.sd.mp4?s=236da2f3c0fd273d2c6d9a064f3ae35579b2bbdf&profile_id=139&oauth2_token_id=57447761";
  videoURL = `https://${videoURL}`;
  // const encodedUrl = encodeURIComponent(videoURL);

  function handleSeasonChange(e) {
    setSelectedSeason(e.target.value);
  }

  return (
    <>
      <div className="featuredSection">
      {type && data && (
        <div className="category">
          <span>{selectedType}</span>
          <select
            name="season"
            id="season"
            onChange={handleSeasonChange}
            value={selectedSeason}
          >
            {seasonList.map((season, index) => {
              return (
                <option value={season} key={index}>
                  {season}
                </option>
              );
            })}
          </select>
        </div>
      )}
      {data && (
        <>
          <img src={imageUrl} alt="Slider Video Image" />
          <div className="info">
            <div className="title">
              <h2>{seasonVideosList[0].videoTitle}</h2>
            </div>
            <span className="desc">
              {seasonVideosList[0].videoDesc || "No additional info available"}
            </span>
            <div className="buttons">
              {/* <Link to={`/watch/${encodedUrl}`}> */}
              <Link to={`/watch/${seasonVideosList[0].id}`}>
                <button className="play">
                  <PlayArrowIcon />
                  <span>Play</span>
                </button>
              </Link>
              <button className="more">
                <InfoOutlinedIcon />
                <span onClick={handleInfoOpen}>Info</span>
                <Modal
                  open={openBool}
                  onClose={handleInfoClose}
                  aria-labelledby={seasonVideosList[0].videoTitle || "Unknow Title"}
                  aria-describedby={seasonVideosList[0].videoDesc || "No additional info available"}>
                  <Box sx={style}>
                    {seasonVideosList[0].videoDesc || "No additional info available"} <br/>
                    <br/>Genres: {[seasonVideosList[0].videoGenres].length ? [seasonVideosList[0].videoGenres].join(",") : "No genres info available"}
                    <br/>Duration: {getFormattedTime(seasonVideosList[0].videoDuration) || "No duration info available"}
                    <br/>Published: {getFormattedDate(seasonVideosList[0].videoPublish) || "No published info available"}
                    <br/>Tagged with: {[seasonVideosList[0].videoTags].length ? [seasonVideosList[0].videoTags].join(",") : "No Tags info available"}
                    <br/>Category: {seasonVideosList[0].videoCategory || "Unassorted video"}
                  </Box>
                </Modal>
              </button>
            </div>
          </div>
        </>
      )}
    </div>

    <List listTitle="Episodes" listData={seasonVideosList} />
    </>
  );
}
