import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import "./watch.scss";
import { Link, useParams } from "react-router-dom";
import ReactBridPlayer from "../../common/bridtv/react-brid-player";
import NotFound from "../404/404";
import config from "../../config";

export default function Watch() {
  let { vidUrl } = useParams();
  // console.log("Recvd vidUrl in watch=", vidUrl);

  // const onAdImpression = (e) => {
  //   console.log('onAdImpression-W', e);
  // }

  if (vidUrl.length === 0) {
    return <NotFound />;
  }

  return (
    <div className="watch">
      <div className="back">
        <Link to="/">
          <ArrowBackIosIcon style={{ paddingTop: "10px" }} />
          <span>Home</span>
        </Link>
      </div>
      {/* <video
        className="video"
        autoPlay
        progress
        controls
        src={vidUrl}
      /> */}
      <ReactBridPlayer
        divId="Brid_12345"
        id={config.bridTV.BRIDTV_PLAYER_ID} // "32097"
        width="1920"
        height="1080"
        video={vidUrl} // '926393'
        // onAdImpression={onAdImpression}
        // divId="Brid_12345"
        // id="32097"  // "31912"
        // width="1920"  //"640"
        // height="1080"  // "360"
        // video={vidUrl}  // '926393'
      />
    </div>
  );
}
